//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters } from 'vuex'
import { integerStyle } from '@/helpers/index'
import { textHighlight, firstTextHighlight } from '~/plugins/text-highlight'
import { formatDateShort, formatTimeShort } from '@/helpers/dateTime'
import { getCateParam } from '@/helpers/categoryParams'
export default {
  name: 'SearchContainer',
  props: {
    isShowSearch: {
      type: Boolean,
      default: false
    },
    keywordSearch: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      recentSearch: [],
      listQuoteData: [],
      listSearchQuote: [],
      isSearch: false,
      isMobile: false,
      isFetchData: false,
      cancelGetInfoToken: null,
      totalResultItem: 7
    }
  },
  // async fetch () {
  //   // await this.getRecentSearch()
  //   // await this.getStockList()
  // },
  computed: {
    ...mapGetters({
      popularKeywords: 'search/getPopularKeywords',
      popular: 'search/getPopular',
      stocks: 'search/getStocks',
      lastNews: 'search/getLastNews',
      news: 'search/getNews'
    }),
    viewMoreUrl () {
      return this.getViewMoreUrl()
    }
  },
  watch: {
    isShowSearch () {
      const bodyTag = document.getElementsByTagName('body')
      const appbarEle = document.getElementById('appbar')
      const searchContentEle = document.getElementsByClassName('search-content')
      if (bodyTag && appbarEle && searchContentEle) {
        if (this.isShowSearch) {
          this.fetchData()
          bodyTag[0].classList.add('search-panel-open')
          if (this.isMobile) {
            const windowHeight = window.innerHeight
            const navbarHeight = this.getNavbarHeight()
            const sectionTopHeight = this.getNavbarHeight()
            const heightOfButtonSearchAll = 90
            const containerHeight = windowHeight - (navbarHeight + sectionTopHeight + heightOfButtonSearchAll)
            searchContentEle[0].style.height = `${containerHeight}px`
            setTimeout(() => {
              searchContentEle[0].style.position = 'fixed'
              searchContentEle[0].style.top = `${appbarEle.getBoundingClientRect()?.bottom ?? 0}px`
            }, 500)
          } else {
            searchContentEle[0].style.position = null
            searchContentEle[0].style.top = null
            searchContentEle[0].style.height = null
          }
        } else {
          bodyTag[0].classList.remove('search-panel-open')
          searchContentEle[0].style.position = null
          searchContentEle[0].style.top = null
          searchContentEle[0].style.height = null
        }
      }
      this.updateHeightContent()
    },
    async keywordSearch () {
      if (this.keywordSearch !== '') {
        this.handleSearch(this.keywordSearch)
        this.isSearch = true
        await this.getNews(this.keywordSearch)
        if (!this.isMobile) {
          this.updateHeightContent()
        }
      } else {
        this.isSearch = false
        if (!this.isMobile) {
          setTimeout(() => {
            this.updateHeightContent()
          }, 300)
        }
      }
    }
  },
  mounted () {
    this.onResize()
    window.addEventListener('resize', this.onResize, { passive: true })
  },
  destroyed () {
    window.removeEventListener('resize', this.onResize, { passive: true })
  },
  methods: {
    ...mapActions('search', ['getPopularKeywords', 'getPopularQuote', 'getStockList', 'getLastNews', 'getNews']),
    integerStyle,
    textHighlight,
    formatDateShort,
    formatTimeShort,
    async fetchData () {
      if (!this.isFetchData) {
        await Promise.allSettled([
          this.getRecentSearch(),
          this.getPopularQuote(),
          this.getPopularKeywords(),
          this.getLastNews()
        ])
        this.isFetchData = true
      }
    },
    sectionTop () {
      return document.getElementById('sectionTop').clientHeight || { clientHeight: 0 }
    },
    getNavbarHeight () {
      return document.getElementById('appbar').clientHeight || 0
    },
    onResize () {
      const screenSize = window.innerWidth
      if (screenSize > 768) {
        this.isMobile = false
      } else {
        this.isMobile = true
      }
    },
    Highlight (content) {
      return this.textHighlight(this.keywordSearch, content)
    },
    highlightFirst (content) {
      return firstTextHighlight(this.keywordSearch, content)
    },
    bgColor (change) {
      if (change === 0) {
        return 'bg-other-gray'
      } else if (change < 0) {
        return 'bg-negative'
      } else if (change > 0) {
        return 'bg-positive'
      }
    },
    getStockSecurityType (symbol) {
      const stockInfo = this.stocks.find((data) => {
        return data.symbol === symbol
      })
      if (stockInfo) {
        return stockInfo.securityType
      }
      return ''
    },
    searchRecentToUrl ($event, symbol) {
      this.$personalized.siteSearch(symbol, 'panel (latest search)')
      this.$personalized.clickElement({
        name: 'all2',
        optionLang: {
          keyword: symbol
        }
      })
      this.handleSelectSearch($event, {
        symbol,
        securityType: this.getStockSecurityType(symbol)
      })
    },
    searchPopularKeywordToUrl (item) {
      this.$personalized.siteSearch(item, 'panel (popular search)')
      this.$personalized.clickElement({
        name: 'all3',
        optionLang: {
          keyword: item
        }
      })
      const quote = {
        symbol: item,
        securityType: this.getStockSecurityType(item)
      }
      const urlString = this.$quote.getQuoteUrl(quote)
      window.location.href = urlString
    },
    searchPopularQuoteToUrl ($event, item) {
      this.$personalized.clickElement({
        name: 'all4',
        optionLang: {
          symbol: item.symbol
        }
      })
      this.toQuote($event, item)
    },
    searchQuoteToUrl ($event, item) {
      this.$personalized.clickElement({
        name: 'all6',
        optionLang: {
          symbol: item.symbol
        }
      })
      this.toQuote($event, item)
    },
    toSearchResultURL () {
      this.$personalized.clickElement({
        name: 'all8'
      })
      this.toUrl(this.viewMoreUrl)
    },
    toQuote ($event, item) {
      this.handleSelectSearch($event, item)
    },
    async handleSelectSearch ($event, item) {
      const symbol = item.symbol
      $event.stopPropagation()
      $event.preventDefault()
      let tempQuote = await this.recentSearch
      const quoteNow = []
      if (symbol) {
        quoteNow.push(symbol)
      }
      if (tempQuote.length) {
        if (quoteNow.length) {
          const isSame = tempQuote.filter(q => q === quoteNow[0])
          if (isSame.length) {
            const index = tempQuote.indexOf(isSame[0])
            if (index > -1) {
              tempQuote.splice(index, 1)
            }
            tempQuote = [...quoteNow, ...tempQuote]
            tempQuote.splice(5, 1)
          } else {
            tempQuote = [...quoteNow, ...tempQuote]
            tempQuote.splice(5, 1)
          }
        }
        this.$cookies.set('recent-search', JSON.stringify(tempQuote))
        this.recentSearch = tempQuote
      } else {
        this.$cookies.set('recent-search', JSON.stringify(quoteNow))
        this.recentSearch = quoteNow
      }
      const urlString = this.$quote.getQuoteUrl(item)
      window.location.href = urlString
      // this.$router.push(urlString)
      this.$emit('close')
    },
    getRecentSearch () {
      if (this.$cookies.get('recent-search')) {
        this.recentSearch = this.$cookies.get('recent-search')
      } else {
        this.recentSearch = []
      }
    },
    async getStockInfo (symbol, cancelGetInfoToken) {
      try {
        const info = await this.$axios.$get(`/api/set/stock/${symbol}/info`, { cancelToken: cancelGetInfoToken.token })
        return info
      } catch (ex) {
        return null
      }
    },
    cancelGetInfo () {
      if (this.cancelGetInfoToken) {
        this.cancelGetInfoToken.cancel()
      }
      this.cancelGetInfoToken = null
    },
    handleSearch (keyword) {
      const tempKeyword = keyword.replace(/\s/g, '').toUpperCase()
      let listSearchQuote = this.stocks.filter(item => item.symbol.replace(/\s/g, '').toUpperCase().lastIndexOf(tempKeyword, 0) === 0)
      // this.listSearchQuote = this.stocks.filter(item => item.symbol.replace(/\s/g, '').toUpperCase().lastIndexOf(tempKeyword, 0) === 0)
      if (!listSearchQuote.length) {
        listSearchQuote = this.stocks.filter(item =>
          item.nameTH.trim().toUpperCase().includes(keyword)
        )
      }
      if (this.cancelGetInfoToken) { this.cancelGetInfo() }
      const axiosSource = this.$axios.CancelToken.source()
      this.cancelGetInfoToken = axiosSource
      const promiseCollection = []
      const length = listSearchQuote.length >= this.totalResultItem ? this.totalResultItem : listSearchQuote.length
      for (let i = 0; i < length; i++) {
        const { symbol, isTFEXSeries, isFund } = listSearchQuote[i] ?? {}
        const isNotStock = isTFEXSeries === true || isFund === true
        if (symbol && isNotStock === false) {
          const info = this.getStockInfo(symbol, this.cancelGetInfoToken) ?? {}
          promiseCollection.push(info)
        }
      }
      const result = []
      Promise.all(promiseCollection).then((infoList) => {
        for (let i = 0; i < length; i++) {
          const { symbol } = listSearchQuote[i] ?? {}
          const { sign } = infoList.find(f => f?.symbol === symbol) ?? {}
          result.push({
            ...listSearchQuote[i],
            sign
          })
        }
        this.listSearchQuote = result
      })

      // if (this.listSearchQuote.length) {
      //   const activeItem = document.querySelector('.cursor-active')
      //   if (activeItem) {
      //     activeItem.classList.remove('cursor-active')
      //   }
      //   const eleList = document.getElementsByClassName('search-list-items')
      //   if (eleList.length) {
      //     eleList[0].classList.add('cursor-active')
      //     document.querySelector('.search-list-content').scrollTop = 0
      //   }
      // }

      // if (!this.listSearchQuote.length) {
      //   this.listSearchQuote = this.listQuoteData
      // }
      // const regex = new RegExp(keyword, 'i')
      // this.listSearchQuote = this.listSearchQuote.map((item, index) => {
      //   return {
      //     market: item.market,
      //     nameEN: item.nameEn,
      //     nameTH: item.nameTH,
      //     securityType: item.securityType,
      //     symbol: item.symbol.replace(regex, `<span>${keyword.toUpperCase()}</span>`),
      //     typeSequence: item.typeSequence
      //   }
      // })
    },
    updateHeightContent () {
      // update height content
      if (this.isShowSearch) {
        const contentHeight = this.$refs.searchContent.offsetHeight
        const searchListEle = this.$refs.searchList
        if (searchListEle) {
          let searchtHeight = searchListEle.offsetHeight
          if (!(this.listSearchQuote && this.listSearchQuote.length > 0)) {
            if (this.recentSearch && this.recentSearch.length) {
              searchtHeight = searchtHeight + 64
            } else {
              searchtHeight = 0
            }
          } else if (this.recentSearch && this.recentSearch.length) {
            searchtHeight = searchtHeight + 48
          } else {
            searchtHeight = searchtHeight - 10
          }
          this.$refs.searchContainer.style.height = `${searchtHeight}px`
        } else if (this.isMobile) {
          const windowHeight = window.innerHeight
          const navbarHeight = this.getNavbarHeight()
          const sectionTopHeight = this.getNavbarHeight()
          const heightOfButtonSearchAll = 90
          const containerHeight = windowHeight - (navbarHeight + sectionTopHeight + heightOfButtonSearchAll)
          this.$refs.searchContainer.style.height = `${containerHeight}px`
        } else {
          this.$refs.searchContainer.style.height = `${contentHeight}px`
        }
      } else {
        this.$refs.searchContainer.style.height = null
      }
    },
    // setCursorActive (e) { // for cursor active
    //   if (this.isShowSearch && document.querySelector('.button-close-searchbox').classList.contains('show')) {
    //     if (e) {
    //       const eleSearchItem = e.path.find(data => data.classList.contains('search-list-items'))
    //       if (eleSearchItem) {
    //         const activeItem = document.querySelector('.cursor-active')
    //         if (activeItem) {
    //           activeItem.classList.remove('cursor-active')
    //         }
    //         eleSearchItem.classList.add('cursor-active')
    //         document.getElementById('inputBox').focus()
    //       }
    //     }
    //   }
    // },
    getViewMoreUrl (keywordSearch = this.keywordSearch) {
      return this.localePath({ path: `/search?keyword=${keywordSearch}` })
    },
    toUrl (url) {
      window.open(url, '_blank')
    },
    toUrlNews (news) {
      if (news.newsSource === 'SETAPI') {
        const { symbol } = news
        window.open(this.localePath({
          path: `/news-and-articles/news/${news.uuid}?symbol=${symbol}`
        }), '_blank')
      } else {
        window.open(news.url, '_blank')
      }
    },
    clickEventLastNews (news) {
      this.$personalized.clickElement({
        name: 'all5',
        optionLang: {
          symbol: news.symbol,
          title: news.title
        }
      })
      this.toUrlNews(news)
    },
    clickEventNews (news) {
      this.$personalized.clickElement({
        name: 'all7',
        optionLang: {
          keyword: news.title
        }
      })
      this.toUrlNews(news)
    },
    goToMainByCate (id) {
      window.open(this.localePath({
        path: `/news-and-articles/news/main?category=${getCateParam(id)}`
      }))
    }
  }
}
