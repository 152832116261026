/**
 * State
 */
const state = () => ({
  clientId: null,
  date: null,
  windowLoaded: false,
  contentDataLayer: {
    currentPath: ''
  }
})

/**
 * Mutations
 */
const mutations = {
  setClientId (state, data) {
    state.clientId = data
  },
  setDate (state, data) {
    state.date = data
  },
  setWindowLoaded (state, data) {
    state.windowLoaded = data
  },
  setContentDataLayerCurrentPath (state, data) {
    state.contentDataLayer.currentPath = data
  }
}
const actions = {
  async nuxtClientInit ({ commit, dispatch }, context) {
    const { route } = context

    // await Promise.allSettled([
    //   dispatch('search/getStockList'),
    //   dispatch('search/getFundVirtualport')
    // ])
    await dispatch('search/getStockList')

    if (!route.fullPath.includes('/authorization')) {
      this.$authUser.authUser()
    }
    const isLogin = await this.$authUser.isLogin()
    if (isLogin) {
      // keep remember Fev Quote
      const favoriteQuoteRemember = this.$cookies.get('favorite-quote-remember')
      if (favoriteQuoteRemember) {
        await this.$favourite.addFavourite(
          favoriteQuoteRemember.category,
          favoriteQuoteRemember.symbol
        )
        this.$cookies.remove('favorite-quote-remember', { path: '/' })
      }
      await this.$favourite.initialization()
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
