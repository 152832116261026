import { render, staticRenderFns } from "./ModalPortfolioName.vue?vue&type=template&id=1cad7c55&scoped=true&"
import script from "./ModalPortfolioName.vue?vue&type=script&lang=js&"
export * from "./ModalPortfolioName.vue?vue&type=script&lang=js&"
import style0 from "./ModalPortfolioName.vue?vue&type=style&index=0&id=1cad7c55&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1cad7c55",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IconClose: require('/app/components/Icon/Close.vue').default,Button: require('/app/components/Button/Button.vue').default,MiscMemberVirtualPortfolioValidationState: require('/app/components/Misc/Member/VirtualPortfolio/ValidationState.vue').default,Button: require('/app/components/Button/Button.vue').default,Modal: require('/app/components/Modal/Modal.vue').default})
