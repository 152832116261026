const contentGroupList = [
  {
    routeName: '^index_',
    teamName: '',
    contentType: 'Dynamic',
    contentSuperGroup: '',
    contentGroup: 'Welcome Page',
    contentCategory: ['welcome page'],
    productName: '',
    ignoreSlug: false
  },
  {
    path: '/home',
    teamName: 'Digital Marketing',
    contentType: '',
    contentSuperGroup: '',
    contentGroup: '',
    productName: '',
    ignoreSlug: false
  },
  {
    path: '/search',
    teamName: 'Digital Marketing',
    contentType: 'Dynamic',
    contentSuperGroup: 'Other',
    contentGroup: 'Search - Quote/Universal',
    productName: ''
  },
  {
    path: '/get-quote',
    teamName: 'Digital Marketing',
    contentType: 'Dynamic',
    contentSuperGroup: 'Other',
    contentGroup: 'Search - Symbol',
    productName: ''
  },
  {
    path: '/equities/market-summary/overview',
    teamName: 'Data Product',
    contentType: 'Dynamic',
    contentSuperGroup: 'equities',
    contentGroup: 'Market Overview',
    productName: 'Equities'
  },
  {
    path: '/equities/market-summary/top-ranking/most-active-volume',
    teamName: 'Data Product',
    contentType: 'Dynamic',
    contentSuperGroup: 'equities',
    contentGroup: 'Market Overview',
    productName: 'Equities'
  },
  {
    path: '/equities/market-summary/top-ranking/most-active-value',
    teamName: 'Data Product',
    contentType: 'Dynamic',
    contentSuperGroup: 'equities',
    contentGroup: 'Market Overview',
    productName: 'Equities'
  },
  {
    path: '/equities/market-summary/top-ranking/top-gainer',
    teamName: 'Data Product',
    contentType: 'Dynamic',
    contentSuperGroup: 'equities',
    contentGroup: 'Market Overview',
    productName: 'Equities'
  },
  {
    path: '/equities/market-summary/top-ranking/top-loser',
    teamName: 'Data Product',
    contentType: 'Dynamic',
    contentSuperGroup: 'equities',
    contentGroup: 'Market Overview',
    productName: 'Equities'
  },
  {
    path: '/equities/market-summary/top-ranking/popular-quote',
    teamName: 'Data Product',
    contentType: 'Dynamic',
    contentSuperGroup: 'equities',
    contentGroup: 'Market Overview',
    productName: 'Equities'
  },
  {
    path: '/equities/market-summary/top-ranking/top-pe',
    teamName: 'Data Product',
    contentType: 'Dynamic',
    contentSuperGroup: 'equities',
    contentGroup: 'Market Overview',
    productName: 'Equities'
  },
  {
    path: '/equities/market-summary/top-ranking/top-dividend-yield',
    teamName: 'Data Product',
    contentType: 'Dynamic',
    contentSuperGroup: 'equities',
    contentGroup: 'Market Overview',
    productName: 'Equities'
  },
  {
    path: '/equities/market-summary/top-ranking/top-eps',
    teamName: 'Data Product',
    contentType: 'Dynamic',
    contentSuperGroup: 'equities',
    contentGroup: 'Market Overview',
    productName: 'Equities'
  },
  {
    path: '/equities/market-summary/top-ranking/top-net-profit-margin',
    teamName: 'Data Product',
    contentType: 'Dynamic',
    contentSuperGroup: 'equities',
    contentGroup: 'Market Overview',
    productName: 'Equities'
  },
  {
    path: '/equities/market-summary/top-ranking/top-roe',
    teamName: 'Data Product',
    contentType: 'Dynamic',
    contentSuperGroup: 'equities',
    contentGroup: 'Market Overview',
    productName: 'Equities'
  },
  {
    path: '/equities/market-summary/top-ranking/top-roa',
    teamName: 'Data Product',
    contentType: 'Dynamic',
    contentSuperGroup: 'equities',
    contentGroup: 'Market Overview',
    productName: 'Equities'
  },
  {
    path: '/equities/market-data/overview',
    teamName: 'Data Product',
    contentType: 'Dynamic',
    contentSuperGroup: 'equities',
    contentGroup: 'Market Summary',
    productName: 'Equities'
  },
  {
    path: '/equities/market-data/historical-report/investor-type',
    teamName: 'Data Product',
    contentType: 'Dynamic',
    contentSuperGroup: 'equities',
    contentGroup: 'Market Summary',
    productName: 'Equities'
  },
  {
    path: '/equities/market-data/historical-report/five-days',
    teamName: 'Data Product',
    contentType: 'Dynamic',
    contentSuperGroup: 'equities',
    contentGroup: 'Market Summary',
    productName: 'Equities'
  },
  {
    path: '/equities/market-data/nvdr/volume',
    teamName: 'Data Product',
    contentType: 'Dynamic',
    contentSuperGroup: 'equities',
    contentGroup: 'Market Summary',
    productName: 'Equities'
  },
  {
    path: '/equities/market-data/nvdr/value',
    teamName: 'Data Product',
    contentType: 'Dynamic',
    contentSuperGroup: 'equities',
    contentGroup: 'Market Summary',
    productName: 'Equities'
  },
  {
    path: '/equities/market-data/shortsell',
    teamName: 'Data Product',
    contentType: 'Dynamic',
    contentSuperGroup: 'equities',
    contentGroup: 'Market Summary',
    productName: 'Equities'
  },
  {
    path: '/equities/market-data/biglot',
    teamName: 'Data Product',
    contentType: 'Dynamic',
    contentSuperGroup: 'equities',
    contentGroup: 'Market Summary',
    productName: 'Equities'
  },
  {
    path: '/equities/ipo-corner/upcoming-ipo/effective-filing',
    teamName: 'Investor Channel',
    contentType: 'Dynamic',
    contentSuperGroup: 'equities',
    contentGroup: 'IPO',
    productName: 'Equities'
  },
  {
    path: '/equities/ipo-corner/upcoming-ipo/approved-application',
    teamName: 'Investor Channel',
    contentType: 'Dynamic',
    contentSuperGroup: 'equities',
    contentGroup: 'IPO',
    productName: 'Equities'
  },
  {
    path: '/equities/ipo-corner/ipo-performance/highlight',
    teamName: 'Investor Channel',
    contentType: 'Dynamic',
    contentSuperGroup: 'equities',
    contentGroup: 'IPO',
    productName: 'Equities'
  },
  {
    path: '/equities/ipo-corner/ipo-performance/profile',
    teamName: 'Investor Channel',
    contentType: 'Dynamic',
    contentSuperGroup: 'equities',
    contentGroup: 'IPO',
    productName: 'Equities'
  },
  {
    path: '/equities/ipo-corner/ipo-performance/price-performance',
    teamName: 'Investor Channel',
    contentType: 'Dynamic',
    contentSuperGroup: 'equities',
    contentGroup: 'IPO',
    productName: 'Equities'
  },
  {
    path: '/equities/ipo-corner/ipo-performance/market-statistics',
    teamName: 'Investor Channel',
    contentType: 'Dynamic',
    contentSuperGroup: 'equities',
    contentGroup: 'IPO',
    productName: 'Equities'
  },
  {
    path: '/equities/ipo-corner/ipo-performance/financial',
    teamName: 'Investor Channel',
    contentType: 'Dynamic',
    contentSuperGroup: 'equities',
    contentGroup: 'IPO',
    productName: 'Equities'
  },
  {
    path: '/equities/ipo-corner/ipo-performance/financial-advisor-statistics',
    teamName: 'Investor Channel',
    contentType: 'Dynamic',
    contentSuperGroup: 'equities',
    contentGroup: 'IPO',
    productName: 'Equities'
  },
  {
    path: '/equities/stock-comparison',
    teamName: 'Investor Channel',
    contentType: 'Dynamic',
    contentSuperGroup: 'equities',
    contentGroup: 'Stock Comparison',
    productName: 'Equities'
  },
  {
    path: '/technical-chart',
    teamName: 'IT Solutions Business Development (Settrade)',
    contentType: 'Dynamic',
    contentSuperGroup: 'Other',
    contentGroup: 'Technical Chart',
    productName: 'Equities'
  },
  {
    path: '/technical-chart?type=s&symbol=PTT',
    teamName: 'IT Solutions Business Development (Settrade)',
    contentType: 'Dynamic',
    contentSuperGroup: 'Other',
    contentGroup: 'Technical Chart',
    productName: 'Equities'
  },
  {
    path: '/equities/stock-screening/dividend/guideline',
    teamName: 'Investor Channel',
    contentType: 'Dynamic',
    contentSuperGroup: 'equities',
    contentGroup: 'Stock Screening',
    productName: 'Equities'
  },
  {
    path: '/equities/stock-screening/dividend/customize',
    teamName: 'Investor Channel',
    contentType: 'Dynamic',
    contentSuperGroup: 'equities',
    contentGroup: 'Stock Screening',
    productName: 'Equities'
  },
  {
    path: '/equities/stock-screening/fundamental/guideline',
    teamName: 'Investor Channel',
    contentType: 'Dynamic',
    contentSuperGroup: 'equities',
    contentGroup: 'Stock Screening',
    productName: 'Equities'
  },
  {
    path: '/equities/stock-screening/fundamental/customize',
    teamName: 'Investor Channel',
    contentType: 'Dynamic',
    contentSuperGroup: 'equities',
    contentGroup: 'Stock Screening',
    productName: 'Equities'
  },
  {
    path: '/equities/stock-screening/growth/guideline',
    teamName: 'Investor Channel',
    contentType: 'Dynamic',
    contentSuperGroup: 'equities',
    contentGroup: 'Stock Screening',
    productName: 'Equities'
  },
  {
    path: '/equities/stock-screening/growth/customize',
    teamName: 'Investor Channel',
    contentType: 'Dynamic',
    contentSuperGroup: 'equities',
    contentGroup: 'Stock Screening',
    productName: 'Equities'
  },
  {
    path: '/equities/stock-screening/customize',
    teamName: 'Investor Channel',
    contentType: 'Dynamic',
    contentSuperGroup: 'equities',
    contentGroup: 'Stock Screening',
    productName: 'Equities'
  },
  {
    path: '/equities/esg-investment/esg-rating',
    teamName: 'Sustainable Development',
    contentType: 'Dynamic',
    contentSuperGroup: 'equities',
    contentGroup: 'ESG Investment',
    productName: 'Equities'
  },
  {
    path: '/equities/esg-investment/esg-index',
    teamName: 'Sustainable Development',
    contentType: 'Dynamic',
    contentSuperGroup: 'equities',
    contentGroup: 'ESG Investment',
    productName: 'Equities'
  },
  {
    path: '/equities/stock-calendar',
    teamName: 'Digital Marketing',
    contentType: 'Dynamic',
    contentSuperGroup: 'equities',
    contentGroup: 'Calendar - Event',
    productName: ''
  },
  {
    path: '/equities/company-snapshot',
    teamName: 'Investor Channel',
    contentType: 'Dynamic',
    contentSuperGroup: 'equities',
    contentGroup: 'Company Snapshot',
    productName: ''
  },
  {
    path: '/equities/etf/overview',
    teamName: 'Retail',
    contentType: 'Dynamic',
    contentSuperGroup: 'equities',
    contentGroup: 'Product - ETF',
    productName: 'ETF'
  },
  {
    path: '/equities/etf/thematic',
    teamName: 'Retail',
    contentType: 'Dynamic',
    contentSuperGroup: 'equities',
    contentGroup: 'Product - ETF',
    productName: 'ETF'
  },
  {
    path: '/equities/etf/screening',
    teamName: 'Retail',
    contentType: 'Dynamic',
    contentSuperGroup: 'equities',
    contentGroup: 'Product - ETF',
    productName: 'ETF'
  },
  {
    path: '/equities/dr/overview',
    teamName: 'Investor Channel',
    contentType: 'Dynamic',
    contentSuperGroup: 'equities',
    contentGroup: 'Product - DR',
    productName: 'DR'
  },
  {
    path: '/equities/drx/overview',
    teamName: 'Investor Channel',
    contentType: 'Dynamic',
    contentSuperGroup: 'equities',
    contentGroup: 'Product - DRx',
    productName: 'DRx'
  },
  {
    path: '/equities/drx/market-data',
    teamName: 'Investor Channel',
    contentType: 'Dynamic',
    contentSuperGroup: 'equities',
    contentGroup: 'Product - DRx',
    productName: 'DRx'
  },
  {
    path: '/equities/drx/screening',
    teamName: 'Investor Channel',
    contentType: 'Dynamic',
    contentSuperGroup: 'equities',
    contentGroup: 'Product - DRx',
    productName: 'DRx'
  },
  {
    path: '/equities/dw/overview',
    teamName: 'Investor Channel',
    contentType: 'Dynamic',
    contentSuperGroup: 'equities',
    contentGroup: 'Product - DW',
    productName: 'DW'
  },
  {
    path: '/equities/dw/screening',
    teamName: 'Investor Channel',
    contentType: 'Dynamic',
    contentSuperGroup: 'equities',
    contentGroup: 'Product - DW',
    productName: 'DW'
  },
  {
    path: '/equities/dw/calculator',
    teamName: 'Investor Channel',
    contentType: 'Dynamic',
    contentSuperGroup: 'equities',
    contentGroup: 'Product - DW',
    productName: 'DW'
  },
  {
    path: '/equities/dw/statistics-report',
    teamName: 'Investor Channel',
    contentType: 'Dynamic',
    contentSuperGroup: 'equities',
    contentGroup: 'Product - DW',
    productName: 'DW'
  },
  {
    path: '/equities/dw/dw-foreign/overview',
    teamName: 'Investor Channel',
    contentType: 'Dynamic',
    contentSuperGroup: 'equities',
    contentGroup: 'Product - DW',
    productName: 'DW'
  },
  {
    path: '/derivatives/overview',
    teamName: 'Derivatives',
    contentType: 'Dynamic',
    contentSuperGroup: 'derivatives',
    contentGroup: 'Product - TFEX',
    productName: 'TFEX'
  },
  {
    path: '/derivatives/market-data/trading-quotation-by-series',
    teamName: 'Derivatives',
    contentType: 'Dynamic',
    contentSuperGroup: 'derivatives',
    contentGroup: 'Product - TFEX',
    productName: 'TFEX'
  },
  {
    path: '/derivatives/market-data/historical-data',
    teamName: 'Derivatives',
    contentType: 'Dynamic',
    contentSuperGroup: 'derivatives',
    contentGroup: 'Product - TFEX',
    productName: 'TFEX'
  },
  {
    path: '/derivatives/market-data/investor-type',
    teamName: 'Derivatives',
    contentType: 'Dynamic',
    contentSuperGroup: 'derivatives',
    contentGroup: 'Product - TFEX',
    productName: 'TFEX'
  },
  {
    path: '/derivatives/market-data/block-trade',
    teamName: 'Derivatives',
    contentType: 'Dynamic',
    contentSuperGroup: 'derivatives',
    contentGroup: 'Product - TFEX',
    productName: 'TFEX'
  },
  {
    path: '/derivatives/comparison',
    teamName: 'Derivatives',
    contentType: 'Dynamic',
    contentSuperGroup: 'derivatives',
    contentGroup: 'Product - TFEX',
    productName: 'TFEX'
  },
  {
    path: '/derivatives/trading-calendar',
    teamName: 'Derivatives',
    contentType: 'Dynamic',
    contentSuperGroup: 'derivatives',
    contentGroup: 'Product - TFEX',
    productName: 'TFEX'
  },
  {
    path: '/mutualfund/overview',
    teamName: 'Retail',
    contentType: 'Dynamic',
    contentSuperGroup: 'mutualfund',
    contentGroup: 'Product - Mutual Fund',
    productName: 'Mutual Fund'
  },
  {
    path: '/mutualfund/thematic',
    teamName: 'Retail',
    contentType: 'Dynamic',
    contentSuperGroup: 'mutualfund',
    contentGroup: 'Product - Mutual Fund',
    productName: 'Mutual Fund'
  },
  {
    path: '/mutualfund/screening',
    teamName: 'Retail',
    contentType: 'Dynamic',
    contentSuperGroup: 'mutualfund',
    contentGroup: 'Product - Mutual Fund',
    productName: 'Mutual Fund'
  },
  {
    path: '/mutualfund/ipo-search',
    teamName: 'Retail',
    contentType: 'Dynamic',
    contentSuperGroup: 'mutualfund',
    contentGroup: 'Product - Mutual Fund',
    productName: 'Mutual Fund'
  },
  {
    path: '/mutualfund/comparison',
    teamName: 'Retail',
    contentType: 'Dynamic',
    contentSuperGroup: 'mutualfund',
    contentGroup: 'Product - Mutual Fund',
    productName: 'Mutual Fund'
  },
  {
    path: '/equities/quote/overview',
    teamName: 'Digital Marketing',
    contentType: 'Dynamic',
    contentSuperGroup: 'equities',
    contentGroup: 'Quote - Overview',
    productName: 'Equities'
  },
  {
    path: '/equities/quote/historical-trading',
    teamName: 'Digital Marketing',
    contentType: 'Dynamic',
    contentSuperGroup: 'equities',
    contentGroup: 'Quote - Historical Price',
    productName: 'Equities'
  },
  {
    path: '/equities/quote/company-profile/profile',
    teamName: 'Digital Marketing',
    contentType: 'Dynamic',
    contentSuperGroup: 'equities',
    contentGroup: 'Quote - Profile',
    productName: 'Equities'
  },
  {
    path: '/equities/quote/company-profile/board-of-directors',
    teamName: 'Digital Marketing',
    contentType: 'Dynamic',
    contentSuperGroup: 'equities',
    contentGroup: 'Quote - Profile',
    productName: 'Equities'
  },
  {
    path: '/equities/quote/company-profile/major-shareholders',
    teamName: 'Digital Marketing',
    contentType: 'Dynamic',
    contentSuperGroup: 'equities',
    contentGroup: 'Quote - Profile',
    productName: 'Equities'
  },
  {
    path: '/equities/quote/financial-statement/five-years',
    teamName: 'Digital Marketing',
    contentType: 'Dynamic',
    contentSuperGroup: 'equities',
    contentGroup: 'Quote - Financial Statement',
    productName: 'Equities'
  },
  {
    path: '/equities/quote/financial-statement/full',
    teamName: 'Digital Marketing',
    contentType: 'Dynamic',
    contentSuperGroup: 'equities',
    contentGroup: 'Quote - Financial Statement',
    productName: 'Equities'
  },
  {
    path: '/equities/quote/financial-statement/latest',
    teamName: 'Digital Marketing',
    contentType: 'Dynamic',
    contentSuperGroup: 'equities',
    contentGroup: 'Quote - Financial Statement',
    productName: 'Equities'
  },
  {
    path: '/equities/quote/rights-benefits',
    teamName: 'Digital Marketing',
    contentType: 'Dynamic',
    contentSuperGroup: 'equities',
    contentGroup: 'Quote - Benefits',
    productName: 'Equities'
  },
  {
    path: '/equities/quote/analyst-consensus',
    teamName: 'Digital Marketing',
    contentType: 'Resource',
    contentSuperGroup: 'equities',
    contentGroup: 'Quote - IAA',
    productName: 'Equities'
  },
  {
    path: '/equities/quote/esg-information',
    teamName: 'Digital Marketing',
    contentType: 'Dynamic',
    contentSuperGroup: 'equities',
    contentGroup: 'Quote - ESG',
    productName: 'Equities'
  },
  {
    path: '/equities/quote/ranking',
    teamName: 'Digital Marketing',
    contentType: 'Dynamic',
    contentSuperGroup: 'equities',
    contentGroup: 'Quote - Sector Comparison',
    productName: 'Equities'
  },
  {
    path: '/equities/quote/company-profile/warrant-info',
    teamName: 'Digital Marketing',
    contentType: 'Dynamic',
    contentSuperGroup: 'equities',
    contentGroup: 'Quote - Warrant Info',
    productName: 'Equities'
  },
  {
    path: '/equities/quote/news',
    teamName: 'Digital Marketing',
    contentType: 'News',
    contentSuperGroup: 'equities',
    contentGroup: 'Quote - News',
    productName: 'Equities'
  },
  {
    path: '/equities/etf/quote/overview',
    teamName: 'Retail',
    contentType: 'Dynamic',
    contentSuperGroup: 'equities',
    contentGroup: 'Quote - Overview',
    productName: 'ETF'
  },
  {
    path: '/equities/etf/quote/historical-trading',
    teamName: 'Retail',
    contentType: 'Dynamic',
    contentSuperGroup: 'equities',
    contentGroup: 'Quote - Historical Price',
    productName: 'ETF'
  },
  {
    path: '/equities/etf/quote/etf-profile/fund-information',
    teamName: 'Retail',
    contentType: 'Dynamic',
    contentSuperGroup: 'equities',
    contentGroup: 'Quote - Profile',
    productName: 'ETF'
  },
  {
    path: '/equities/etf/quote/etf-profile/asset-allocation',
    teamName: 'Retail',
    contentType: 'Dynamic',
    contentSuperGroup: 'equities',
    contentGroup: 'Quote - Profile',
    productName: 'ETF'
  },
  {
    path: '/equities/etf/quote/etf-profile/major-shareholders',
    teamName: 'Retail',
    contentType: 'Dynamic',
    contentSuperGroup: 'equities',
    contentGroup: 'Quote - Profile',
    productName: 'ETF'
  },
  {
    path: '/equities/etf/quote/historical-performance',
    teamName: 'Retail',
    contentType: 'Dynamic',
    contentSuperGroup: 'equities',
    contentGroup: 'Quote - Fund Performance',
    productName: 'ETF'
  },
  {
    path: '/equities/etf/quote/rights-benefits',
    teamName: 'Retail',
    contentType: 'Dynamic',
    contentSuperGroup: 'equities',
    contentGroup: 'Quote - Benefits',
    productName: 'ETF'
  },
  {
    path: '/equities/etf/quote/research',
    teamName: 'Retail',
    contentType: 'Resource',
    contentSuperGroup: 'equities',
    contentGroup: 'Quote - Research',
    productName: 'ETF'
  },
  {
    path: '/equities/etf/quote/news',
    teamName: 'Retail',
    contentType: 'News',
    contentSuperGroup: 'equities',
    contentGroup: 'Quote - News',
    productName: 'ETF'
  },
  {
    path: '/equities/dr/quote/overview',
    teamName: 'Investor Channel',
    contentType: 'Dynamic',
    contentSuperGroup: 'equities',
    contentGroup: 'Quote - Overview',
    productName: 'DR'
  },
  {
    path: '/equities/dr/quote/historical-trading',
    teamName: 'Investor Channel',
    contentType: 'Dynamic',
    contentSuperGroup: 'equities',
    contentGroup: 'Quote - Historical Price',
    productName: 'DR'
  },
  {
    path: '/equities/dr/quote/factsheet',
    teamName: 'Investor Channel',
    contentType: 'Dynamic',
    contentSuperGroup: 'equities',
    contentGroup: 'Quote - Factsheet',
    productName: 'DR'
  },
  {
    path: '/equities/dr/quote/rights-benefits',
    teamName: 'Investor Channel',
    contentType: 'Dynamic',
    contentSuperGroup: 'equities',
    contentGroup: 'Quote - Benefits',
    productName: 'DR'
  },
  {
    path: '/equities/dr/quote/research',
    teamName: 'Investor Channel',
    contentType: 'Resource',
    contentSuperGroup: 'equities',
    contentGroup: 'Quote - Research',
    productName: 'DR'
  },
  {
    path: '/equities/dr/quote/news',
    teamName: 'Investor Channel',
    contentType: 'News',
    contentSuperGroup: 'equities',
    contentGroup: 'Quote - News',
    productName: 'DR'
  },
  {
    path: '/equities/drx/quote/rights-benefits',
    teamName: 'Investor Channel',
    contentType: 'Dynamic',
    contentSuperGroup: 'equities',
    contentGroup: 'Quote - Benefits',
    productName: 'DRx'
  },
  {
    path: '/equities/drx/quote/research',
    teamName: 'Investor Channel',
    contentType: 'Resource',
    contentSuperGroup: 'equities',
    contentGroup: 'Quote - Research',
    productName: 'DRx'
  },
  {
    path: '/equities/drx/quote/news',
    teamName: 'Investor Channel',
    contentType: 'News',
    contentSuperGroup: 'equities',
    contentGroup: 'Quote - News',
    productName: 'DRx'
  },
  {
    path: '/equities/dw/quote/overview',
    teamName: 'Investor Channel',
    contentType: 'Dynamic',
    contentSuperGroup: 'equities',
    contentGroup: 'Quote - Overview',
    productName: 'DW'
  },
  {
    path: '/equities/dw/quote/historical-trading',
    teamName: 'Investor Channel',
    contentType: 'Dynamic',
    contentSuperGroup: 'equities',
    contentGroup: 'Quote - Historical Price',
    productName: 'DW'
  },
  {
    path: '/equities/dw/quote/factsheet',
    teamName: 'Investor Channel',
    contentType: 'Dynamic',
    contentSuperGroup: 'equities',
    contentGroup: 'Quote - Factsheet',
    productName: 'DW'
  },
  {
    path: '/equities/dw/quote/research',
    teamName: 'Investor Channel',
    contentType: 'Resource',
    contentSuperGroup: 'equities',
    contentGroup: 'Quote - Research',
    productName: 'DW'
  },
  {
    path: '/equities/dw/quote/news',
    teamName: 'Investor Channel',
    contentType: 'News',
    contentSuperGroup: 'equities',
    contentGroup: 'Quote - News',
    productName: 'DW'
  },
  {
    path: '/derivatives/quote/overview',
    teamName: 'Derivatives',
    contentType: 'Dynamic',
    contentSuperGroup: 'derivatives',
    contentGroup: 'TFEX Quote - Overview',
    productName: 'TFEX'
  },
  {
    path: '/derivatives/quote/historical-trading',
    teamName: 'Derivatives',
    contentType: 'Dynamic',
    contentSuperGroup: 'derivatives',
    contentGroup: 'TFEX Quote - Historical Price',
    productName: 'TFEX'
  },
  {
    path: '/derivatives/quote/analyst-consensus',
    teamName: 'Derivatives',
    contentType: 'Resource',
    contentSuperGroup: 'derivatives',
    contentGroup: 'TFEX Quote - IAA',
    productName: 'TFEX'
  },
  {
    path: '/derivatives/quote/research',
    teamName: 'Derivatives',
    contentType: 'Resource',
    contentSuperGroup: 'derivatives',
    contentGroup: 'TFEX Quote - Research',
    productName: 'TFEX'
  },
  {
    path: '/derivatives/quote/trading-calendar',
    teamName: 'Derivatives',
    contentType: 'Dynamic',
    contentSuperGroup: 'derivatives',
    contentGroup: 'TFEX Quote - Trading Calendar',
    productName: 'TFEX'
  },
  {
    path: '/mutualfund/quote/overview',
    teamName: 'Retail',
    contentType: 'Dynamic',
    contentSuperGroup: 'mutualfund',
    contentGroup: 'Mutual Fund Quote - Overview',
    productName: 'Mutual Fund'
  },
  {
    path: '/mutualfund/quote/performance',
    teamName: 'Retail',
    contentType: 'Dynamic',
    contentSuperGroup: 'mutualfund',
    contentGroup: 'Mutual Fund Quote - Fund Performance',
    productName: 'Mutual Fund'
  },
  {
    path: '/mutualfund/quote/asset-allocation',
    teamName: 'Retail',
    contentType: 'Dynamic',
    contentSuperGroup: 'mutualfund',
    contentGroup: 'Mutual Fund Quote - Asset Allocation',
    productName: 'Mutual Fund'
  },
  {
    path: '/mutualfund/quote/fee',
    teamName: 'Retail',
    contentType: 'Dynamic',
    contentSuperGroup: 'mutualfund',
    contentGroup: 'Mutual Fund Quote - Fees',
    productName: 'Mutual Fund'
  },
  {
    path: '/mutualfund/quote/research',
    teamName: 'Retail',
    contentType: 'Resource',
    contentSuperGroup: 'mutualfund',
    contentGroup: 'Mutual Fund Quote - Research',
    productName: 'Mutual Fund'
  },
  {
    path: '/research/analyst-research/main',
    teamName: 'Digital Marketing',
    contentType: 'Resource',
    contentSuperGroup: 'research',
    contentGroup: 'Research',
    productName: ''
  },
  {
    path: '/research/analyst-research',
    teamName: 'Digital Marketing',
    contentType: 'Resource',
    contentSuperGroup: 'research',
    contentGroup: 'Research',
    productName: ''
  },
  {
    path: '/research/iaa-consensus/main',
    teamName: 'Digital Marketing',
    contentType: 'Resource',
    contentSuperGroup: 'research',
    contentGroup: 'Research - IAA',
    productName: ''
  },
  {
    path: '/research/iaa-consensus',
    teamName: 'Digital Marketing',
    contentType: 'Resource',
    contentSuperGroup: 'research',
    contentGroup: 'Research - IAA',
    productName: ''
  },
  {
    path: '/research/iaa-tfex-consensus/main',
    teamName: 'Digital Marketing',
    contentType: 'Resource',
    contentSuperGroup: 'research',
    contentGroup: 'Research - IAA - TFEX',
    productName: ''
  },
  {
    path: '/research/iaa-tfex-consensus',
    teamName: 'Digital Marketing',
    contentType: 'Resource',
    contentSuperGroup: 'research',
    contentGroup: 'Research - IAA - TFEX',
    productName: ''
  },
  {
    path: '/news-and-articles/news/main',
    teamName: 'Digital Marketing',
    contentType: 'News',
    contentSuperGroup: 'news-and-articles',
    contentGroup: 'News and Article - News',
    productName: ''
  },
  {
    path: '/news-and-articles/news',
    teamName: 'Digital Marketing',
    contentType: 'News',
    contentSuperGroup: 'news-and-articles',
    contentGroup: 'News and Article - News',
    productName: ''
  },
  {
    path: '/news-and-articles/articles/main',
    teamName: 'Digital Marketing',
    contentType: 'Article',
    contentSuperGroup: 'news-and-articles',
    contentGroup: 'News and Article - Article',
    productName: ''
  },
  {
    path: '/economic-data/foreign-exchange-rate',
    teamName: 'Digital Marketing',
    contentType: 'Dynamic',
    contentSuperGroup: 'economic-data',
    contentGroup: 'Economic',
    productName: ''
  },
  {
    path: '/economic-data/interest-rate/deposit',
    teamName: 'Digital Marketing',
    contentType: 'Dynamic',
    contentSuperGroup: 'economic-data',
    contentGroup: 'Economic',
    productName: ''
  },
  {
    path: '/economic-data/interest-rate/loan',
    teamName: 'Digital Marketing',
    contentType: 'Dynamic',
    contentSuperGroup: 'economic-data',
    contentGroup: 'Economic',
    productName: ''
  },
  {
    path: '/economic-data/inflation',
    teamName: 'Digital Marketing',
    contentType: 'Dynamic',
    contentSuperGroup: 'economic-data',
    contentGroup: 'Economic',
    productName: ''
  },
  {
    path: '/economic-data/metal-prices',
    teamName: 'Digital Marketing',
    contentType: 'Dynamic',
    contentSuperGroup: 'economic-data',
    contentGroup: 'Economic',
    productName: ''
  },
  {
    path: '/economic-data/oil-prices',
    teamName: 'Digital Marketing',
    contentType: 'Dynamic',
    contentSuperGroup: 'economic-data',
    contentGroup: 'Economic',
    productName: ''
  },
  {
    path: '/economic-data/gold-prices',
    teamName: 'Digital Marketing',
    contentType: 'Dynamic',
    contentSuperGroup: 'economic-data',
    contentGroup: 'Economic',
    productName: ''
  },
  {
    path: '/recently-viewed',
    teamName: 'Digital Marketing',
    contentType: 'Dynamic',
    contentSuperGroup: 'Other',
    contentGroup: 'Recently Viewed',
    productName: ''
  },
  {
    path: '/member/my-feed',
    teamName: 'Digital Marketing',
    contentType: 'Dynamic',
    contentSuperGroup: 'member',
    contentGroup: 'SET Member',
    productName: ''
  },
  {
    path: '/member/favourite',
    teamName: 'Digital Marketing',
    contentType: 'Dynamic',
    contentSuperGroup: 'member',
    contentGroup: 'SET Member',
    productName: ''
  },
  {
    path: '/member/virtual-portfolio',
    teamName: 'Digital Marketing',
    contentType: 'Dynamic',
    contentSuperGroup: 'member',
    contentGroup: 'SET Member',
    productName: ''
  },
  {
    path: '/member/backtesting',
    teamName: 'Digital Marketing',
    contentType: 'Dynamic',
    contentSuperGroup: 'member',
    contentGroup: 'SET Member',
    productName: ''
  },
  {
    path: '/equities/market-summary/top-ranking/overview',
    teamName: 'Data Product',
    contentType: 'Dynamic',
    contentSuperGroup: 'equities',
    contentGroup: 'Market Overview',
    productName: 'Equities'
  },
  {
    path: '/error/404',
    teamName: '',
    contentType: '',
    contentCategory: [''],
    contentSuperGroup: 'error',
    contentGroup: 'error',
    productName: ''
  }
]

export {
  contentGroupList
}
