import { render, staticRenderFns } from "./QuoteButton.vue?vue&type=template&id=286dd97d&scoped=true&"
import script from "./QuoteButton.vue?vue&type=script&lang=js&"
export * from "./QuoteButton.vue?vue&type=script&lang=js&"
import style0 from "./QuoteButton.vue?vue&type=style&index=0&id=286dd97d&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "286dd97d",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IconFavoriteQuote: require('/app/components/Icon/FavoriteQuote.vue').default})
