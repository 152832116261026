import { render, staticRenderFns } from "./SearchContainer.vue?vue&type=template&id=2eef7590&"
import script from "./SearchContainer.vue?vue&type=script&lang=js&"
export * from "./SearchContainer.vue?vue&type=script&lang=js&"
import style0 from "./SearchContainer.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IconSearchRecent: require('/app/components/Icon/SearchRecent.vue').default,Button: require('/app/components/Button/Button.vue').default,IconSearch: require('/app/components/Icon/Search.vue').default,IconPopular: require('/app/components/Icon/Popular.vue').default,SymbolSign: require('/app/components/SymbolSign.vue').default,SearchMiscPriceTag: require('/app/components/Pages/search/Misc/PriceTag.vue').default,IconNews: require('/app/components/Icon/News.vue').default,MiscNewsAndArticlesTagSymbol: require('/app/components/Misc/NewsAndArticles/TagSymbol.vue').default,IconChartUp: require('/app/components/Icon/ChartUp.vue').default,RawHtml: require('/app/components/RawHtml.vue').default,SearchMiscSecurityTypeTag: require('/app/components/Pages/search/Misc/SecurityTypeTag.vue').default})
